import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    // Roles --------------------
    addRole(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/roles', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateRole(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/roles/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteRole(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/roles/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRoles(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/roles', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // -------Permission Types --------------
    fetchPermissionTypes(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/roles-permission-types', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
