<template>

  <div>

    <b-row>
      <b-col md="4">
        <b-card
          class="mb-0"
        >

          <b-row>
            <b-col md="12">
              <div class="d-flex align-items-center justify-content-end">

                <b-button
                  size="sm"
                  variant="primary"
                  @click="resetRoleData()"
                >
                  {{ $t('Yeni Rol Ekle') }}
                </b-button>
              </div>
              <hr>
              <b-list-group>
                <b-list-group-item
                  v-for="(item,index) in rolesData"
                  :key="index"
                  class="d-flex justify-content-between align-items-center"
                >{{ item.name }}
                  <b-dropdown
                    variant="link"
                    no-caret
                    size="sm"
                    :right="$store.state.appConfig.isRTL"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="12"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item @click="selectDataForEdit(item)">
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">{{ $t('Düzenle') }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="!item.is_admin"
                      variant="danger"
                      @click="deleteData(index,item.id)"
                    >
                      <feather-icon icon="Trash2Icon" />
                      <span class="align-middle ml-50">{{ $t('Sil') }}</span>
                    </b-dropdown-item>
                  </b-dropdown></b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>

        </b-card>
      </b-col>
      <b-col md="8">
        <b-card
          v-if="selectedRole"
          class="mb-0"
        >
          <b-card-title v-if="selectedRole.id==0">
            Yeni Rol Oluştur
          </b-card-title>
          <b-card-title v-if="selectedRole.id!=0 && !selectedRole.is_admin">
            {{ selectedRole.name }} İsimli Rolü Düzenle
          </b-card-title>
          <b-card-title v-if="selectedRole.is_admin">
            {{ selectedRole.name }} Rolü Düzenlenemez
          </b-card-title>
          <b-row>
            <b-col md="12">

              <validation-observer
                #default="{ handleSubmit }"
                ref="refFormObserver"
              >
                <!-- Form -->
                <b-form
                  class="p-2"
                  @submit.prevent="handleSubmit(saveData)"
                  @reset.prevent="resetForm"
                >

                  <!-- Firma ad -->
                  <validation-provider
                    #default="validationContext"
                    :name="$t('Rol Adı')"
                    rules="required|min:2"
                  >
                    <b-form-group
                      :label="$t('Rol Adı')"
                      label-for="name"
                    >
                      <b-form-input
                        id="name"
                        v-model="selectedRole.name"
                        autofocus
                        :state="getValidationState(validationContext)"
                        :readonly="selectedRole.is_admin==true"
                        trim
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <div
                    v-for="(item,index) in permissionTypes"
                    :key="index"
                  >
                    <hr>
                    <span><strong>{{ item.type_name }}</strong></span>

                    <b-list-group>
                      <b-list-group-item
                        v-for="(item2,index2) in item.role_types"
                        :key="index2"
                        class="d-flex"
                      >
                        <span class="mr-1">
                          <b-form-checkbox
                            v-model="item2.selected"
                            :disabled="selectedRole.is_admin==true"
                            :value="true"
                            :unchecked-value="false"
                          />
                        </span>
                        <span>{{ item2.description }}</span>
                      </b-list-group-item>

                    </b-list-group>
                  </div>
                  <!-- Form Actions -->
                  <div
                    v-if="!selectedRole.is_admin"
                    class="d-flex mt-2"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-2"
                      type="submit"
                    >
                      {{ $t('Kaydet') }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="button"
                      variant="outline-secondary"
                    >
                      {{ $t('Vazgeç') }}
                    </b-button>
                  </div>

                </b-form>
              </validation-observer>
              <hr>

            </b-col>
          </b-row>

        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import {
  BCard, BRow, BCol, VBModal, BListGroup, BListGroupItem, BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BDropdown, BDropdownItem, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import rolesStoreModule from './rolesStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BListGroup,
    BButton,
    BListGroupItem,
    BFormInvalidFeedback,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BForm,
    BFormGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BCardTitle,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      showMarkAsReturnedModal: false,
      returnCompletionDate: '',
      showEditSidebar: false,
    }
  },
  methods: {
    selectDataForEdit(item) {
      this.resetRoleData()
      this.selectedRole = JSON.parse(JSON.stringify(item))

      const permissionsArr = this.selectedRole.permissions.split(',')
      this.permissionTypes.forEach(val => {
        val.role_types.forEach(val2 => {
          if (permissionsArr.includes(val2.id.toString())) {
            // eslint-disable-next-line no-param-reassign
            val2.selected = true
            // alert('asd')
          }
        })
      })
    },
    // eslint-disable-next-line no-unused-vars
    saveData() {
      const selectedPermissionsData = []
      this.permissionTypes.forEach(val => {
        val.role_types.forEach(val2 => {
          if (val2.selected === true) {
            selectedPermissionsData.push(val2.id)
          }
        })
      })

      this.selectedRole.permissions = selectedPermissionsData.toString()
      if (this.selectedRole.id !== 0) {
        store.dispatch('app-role/updateRole', this.selectedRole)
          .then(response => {
            this.rolesData.forEach((val, index) => {
              if (val.id === this.selectedRole.id) {
                this.rolesData[index] = response.data.data
              }
            })
            this.showEditSidebar = false
            this.showSuccessMessage()
          })
          .catch(() => {
            this.showErrorMessage()
          })
      } else {
        store.dispatch('app-role/addRole', this.selectedRole)
          .then(response => {
            this.resetRoleData()
            this.rolesData.push(response.data.data)
            this.showSuccessMessage()
          })
          .catch(() => {
            this.showErrorMessage()
          })
      }
    },
    // eslint-disable-next-line no-unused-vars
    deleteData(index, id) {
      this.$swal({
        title: this.$t('Emin Misiniz ?'),
        text: this.$t('BuRolü Silmek İstediğinizden Emin Misiniz ? (Bu işlem geri alınamaz.)'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Silme İşlemini Onayla'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('app-role/deleteRole', { id })
            .then(() => {
              this.rolesData.splice(index, 1)
              this.showSuccessMessage()
            })
            .catch(() => {
              this.showErrorMessage()
            })
        }
      })
    },

    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-role'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, rolesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
    const rolesData = ref([])
    const selectedRole = ref(null)
    const permissionTypesRaw = ref([])
    const permissionTypes = ref([])
    const blankRoleData = {
      id: 0,
      name: '',
      is_admin: false,
      permissions: '',

    }
    const fetchPermissionTypes = () => {
      store.dispatch('app-role/fetchPermissionTypes', {})
        .then(response => {
          const { data } = response.data
          data.forEach(val => {
            val.role_types.forEach(val2 => {
              // eslint-disable-next-line no-param-reassign
              val2.selected = false
            })
          })
          permissionTypesRaw.value = data
        })
        .catch(() => {
          alert('Bilgiler getirilirken hata oluştu')
        })
    }
    fetchPermissionTypes()
    const fetchrolesData = () => {
      store.dispatch('app-role/fetchRoles', {})
        .then(response => {
          const { data } = response.data
          rolesData.value = data
        })
        .catch(() => {
          alert('Bilgiler getirilirken hata oluştu')
        })
    }
    fetchrolesData()

    const resetRoleData = () => {
      selectedRole.value = JSON.parse(JSON.stringify(blankRoleData))

      permissionTypes.value = JSON.parse(JSON.stringify(permissionTypesRaw.value))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetRoleData)
    return {
      rolesData,
      blankRoleData,
      fetchrolesData,
      refFormObserver,
      getValidationState,
      resetRoleData,
      permissionTypes,
      selectedRole,
      resetForm,
      snowOption: {
        theme: 'snow',
      },
    }
  },
}
</script>

<style scoped>
.per-page-selector {
  width: 90px;
}
.list-group-item {
  transition: all 1s
}
#add-new-role-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
